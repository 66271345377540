// src/ThemeContext.js

import React, { createContext, useState, useMemo, useEffect } from 'react';
import { createTheme } from '@mui/material/styles';

// Create a Context for the theme
export const ThemeContext = createContext();

const ThemeProviderComponent = ({ children }) => {
  // Retrieve the saved theme and text size preference from localStorage or use defaults
  const savedTheme = localStorage.getItem('appTheme') || 'light';
  const savedLargeText = localStorage.getItem('largeTextEnabled') === 'true';

  const [mode, setMode] = useState(savedTheme);
  const [largeTextEnabled, setLargeTextEnabled] = useState(savedLargeText);

  // Update localStorage whenever the theme changes
  useEffect(() => {
    localStorage.setItem('appTheme', mode);
  }, [mode]);

  // Update localStorage whenever largeTextEnabled changes
  useEffect(() => {
    localStorage.setItem('largeTextEnabled', largeTextEnabled);
  }, [largeTextEnabled]);

  // Toggle between 'light' and 'dark' modes
  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  // Toggle large text on and off
  const toggleLargeText = () => {
    setLargeTextEnabled((prev) => !prev);
  };

  // Define primary and secondary colors
  const primaryColor = '#1976d2'; // Blue
  const secondaryColor = '#dc004e'; // Pink

  // Choose a base font size depending on large text setting
  // Default body font size is typically 14px. Large text could be ~18px.
  const baseFontSize = largeTextEnabled ? 18 : 14;

  // Create a theme instance based on the current mode and largeTextEnabled
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: primaryColor,
          },
          secondary: {
            main: secondaryColor,
          },
          background: {
            default: mode === 'light' ? '#f5f5f5' : '#121212',
            paper: mode === 'light' ? '#ffffff' : '#1d1d1d',
          },
        },
        typography: {
          // Use our adjustable base font size
          fontSize: baseFontSize,
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          h4: {
            fontWeight: 600,
          },
          button: {
            textTransform: 'none',
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 8,
              },
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                marginTop: 8,
                marginBottom: 8,
              },
            },
          },
        },
      }),
    [mode, largeTextEnabled]
  );

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme, largeTextEnabled, toggleLargeText }}>
      {children(theme)}
    </ThemeContext.Provider>
  );
};

export default ThemeProviderComponent;
