// src/components/Footer.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Link as MuiLink,
  Grid,
  TextField,
  IconButton,
  Button,
  Container,
  Divider,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
  Public as PublicIcon,
  AlternateEmail as AlternateEmailIcon,
  MusicNote as MusicNoteIcon
} from '@mui/icons-material';

import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getApp } from 'firebase/app';

const FooterWrapper = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.grey[100],
  marginTop: theme.spacing(12),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(6),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `url('/images/pattern-dark.svg') repeat`,
    opacity: 0.05,
    pointerEvents: 'none',
  },
}));

const FooterLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.grey[300],
  textDecoration: 'none',
  display: 'block',
  marginBottom: theme.spacing(1),
  fontSize: '0.95rem',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.light,
  },
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[300],
  marginRight: theme.spacing(1.5),
  transition: 'color 0.2s ease',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

function Footer() {
  const currentYear = new Date().getFullYear();
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState('');

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const db = getFirestore(getApp());

  const handleToggleForm = () => {
    setShowForm(!showForm);
    setError('');
    setIsSubscribed(false);
  };

  const handleSubscribe = async () => {
    if (!email || !name) {
      setError('Please enter both your name and email.');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      // Add document to Firestore to trigger Mailchimp sync
      await addDoc(collection(db, 'registrations'), {
        emailAddress: email,
        firstName: name
      });

      // Reset form state
      setEmail('');
      setName('');
      setIsSubscribed(true);
      setShowForm(false);
    } catch (err) {
      console.error(err);
      setError('An error occurred while subscribing. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FooterWrapper>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          {/* Company Info */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Orthinas Forms
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, opacity: 0.8, lineHeight: 1.6 }}>
              Empowering teams with intelligent, AI-driven form solutions to streamline data collection and insights.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, opacity: 0.7 }}>
              Orthinas is a brand by RCF.
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.7 }}>
              © {currentYear} Orthinas Forms. All rights reserved.
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Quick Links
            </Typography>
            <FooterLink component={RouterLink} to="/company">Company</FooterLink>
            <FooterLink component={RouterLink} to="/contact-sales">Contact Sales</FooterLink>
            <FooterLink component={RouterLink} to="/pricing">Pricing</FooterLink>
            <FooterLink component={RouterLink} to="/help-and-support">Help & Support</FooterLink>
          </Grid>

          {/* Legal and Account */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Legal
            </Typography>
            <FooterLink component={RouterLink} to="/policy/privacy">Privacy Policy</FooterLink>
            <FooterLink component={RouterLink} to="/policy/terms">Terms of Service</FooterLink>

            <Divider sx={{ my: 2, backgroundColor: 'rgba(255,255,255,0.1)' }} />

            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Account
            </Typography>
            <FooterLink component={RouterLink} to="/login">Login</FooterLink>
            <FooterLink component={RouterLink} to="/register">Sign Up</FooterLink>
          </Grid>

          {/* Newsletter & Social */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Stay in the Loop
            </Typography>

            {!isSubscribed && !showForm && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ mb: 2, opacity: 0.8, lineHeight: 1.6 }}>
                  Subscribe to our newsletter for product updates, tips, and special offers.
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: 20,
                    borderColor: 'rgba(255,255,255,0.5)',
                    color: '#fff',
                    '&:hover': {
                      borderColor: '#fff',
                    },
                  }}
                  onClick={handleToggleForm}
                >
                  Subscribe
                </Button>
              </Box>
            )}

            {showForm && !isSubscribed && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 3 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{
                    input: {
                      color: '#fff',
                      '::placeholder': { color: 'rgba(255,255,255,0.7)' }
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(255,255,255,0.5)',
                      },
                      '&:hover fieldset': {
                        borderColor: '#fff',
                      },
                    },
                  }}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    input: {
                      color: '#fff',
                      '::placeholder': { color: 'rgba(255,255,255,0.7)' }
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(255,255,255,0.5)',
                      },
                      '&:hover fieldset': {
                        borderColor: '#fff',
                      },
                    },
                  }}
                />

                {error && (
                  <Typography variant="body2" sx={{ color: 'red', mb: 1 }}>
                    {error}
                  </Typography>
                )}

                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 'bold',
                      borderRadius: 20,
                      borderColor: 'rgba(255,255,255,0.5)',
                      color: '#fff',
                      '&:hover': {
                        borderColor: '#fff',
                      },
                    }}
                    onClick={handleSubscribe}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <CircularProgress size={16} sx={{ color: '#fff' }} /> : 'Submit'}
                  </Button>

                  <Button
                    variant="text"
                    size="small"
                    color="inherit"
                    onClick={handleToggleForm}
                    sx={{
                      textTransform: 'none',
                      color: '#fff',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}

            {isSubscribed && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ color: 'lightgreen', mb: 1 }}>
                  Thank you for subscribing! Check your inbox for more details.
                </Typography>
              </Box>
            )}

            <Box>

            <SocialIconButton
                component="a"
                href="https://www.facebook.com/profile.php?id=61570217434140"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FacebookIcon />
              </SocialIconButton>

              <SocialIconButton
                component="a"
                href="https://www.instagram.com/orthinasforms/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <InstagramIcon />
              </SocialIconButton>

              <SocialIconButton
                component="a"
                href="https://www.threads.net/@orthinasforms"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Threads"
              >
                <AlternateEmailIcon />
              </SocialIconButton>

              <SocialIconButton
                component="a"
                href="https://x.com/orthinasforms"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="X (Twitter)"
              >
                <TwitterIcon />
              </SocialIconButton>

              <SocialIconButton
                component="a"
                href="https://www.tiktok.com/@orthinas.forms"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="TikTok"
              >
                <MusicNoteIcon />
              </SocialIconButton>

              <SocialIconButton
                component="a"
                href="https://www.youtube.com/channel/UCTMdfrrWF2neRxVNV7yL7Kg"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
              >
                <YouTubeIcon />
              </SocialIconButton>

              <SocialIconButton
                component="a"
                href="https://www.linkedin.com/company/105839041/admin/dashboard/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedInIcon />
              </SocialIconButton>

              <SocialIconButton
                component="a"
                href="https://bsky.app/profile/orthinasforms.bsky.social"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="BlueSky"
              >
                <PublicIcon />
              </SocialIconButton>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 8, mb: 4, backgroundColor: 'rgba(255,255,255,0.1)' }} />

        <Typography variant="body2" color="grey.400" textAlign="center" sx={{ opacity: 0.8 }}>
          Orthinas is a product built by the RCF in the UK
        </Typography>
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
