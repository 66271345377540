// src/App.js
import React, { Suspense } from 'react';
import { Alert } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase.js';
import { useLocation } from 'react-router-dom';
import NavBar from '/Users/willradley/connectly/src/components/Navbar.js'
import Footer from './components/Footer'; // Footer if you have one
import Fallback from './components/Fallback';
import AppRoutes from './AppRoutes'; // Your main routes


function App() {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();

  // While user is loading, show a fallback
  if (loading) {
    return (
      <Suspense fallback={<Fallback />}>
        <Fallback />
      </Suspense>
    );
  }

  // If there's an auth error, display it
  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error.message}
      </Alert>
    );
  }

  return (
    <>
      {/* Always show NavBar */}
      <NavBar />

      <Suspense fallback={<Fallback />}>
        <AppRoutes />
      </Suspense>

      {/* Always show Footer if desired */}
      <Footer />
    </>
  );
}

export default App;
