// src/components/Fallback.js

import React from 'react';
import { CircularProgress, Box } from '@mui/material';

/**
 * Fallback Component
 * Displays a centered loading spinner.
 */
const Fallback = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
    <CircularProgress />
  </Box>
);

export default Fallback;
