// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ThemeProviderComponent from './ThemeContext'; // Import ThemeProvider
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; // Normalize styles
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import { Elements } from '@stripe/react-stripe-js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <ThemeProviderComponent>
      {(theme) => (
        <ThemeProvider theme={theme}>
          <CssBaseline /> {/* Apply baseline CSS */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter> {/* Enable routing */}
              <App />
  
          </BrowserRouter>
          </LocalizationProvider>,
        </ThemeProvider>
      )}
    </ThemeProviderComponent>
  </React.StrictMode>
);
