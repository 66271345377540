// src/pages/FeaturePage.js
import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Link,
  Alert,
  Button,
  useMediaQuery,
  Grid,
  Divider,
  Breadcrumbs
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoIcon from '@mui/icons-material/Info';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

// Styled components
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  color: theme.palette.common.white,
  paddingTop: theme.spacing(14),
  paddingBottom: theme.spacing(14),
  background: `linear-gradient(135deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.main})`,
  backgroundSize: '400% 400%',
  animation: 'gradientBG 15s ease infinite',
  overflow: 'hidden',
  '@keyframes gradientBG': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
}));

const BadgeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  marginTop: theme.spacing(2),
}));

const Badge = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.75rem',
  fontWeight: 500,
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.primary.dark,
  },
}));

const RelatedFeatureCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: theme.shadows[5],
  },
}));

const RelatedCardContent = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
}));

const RelatedCardActions = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'right',
}));

const CTASection = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main} 80%)`,
  color: theme.palette.common.white,
  padding: theme.spacing(10, 2),
  marginTop: theme.spacing(10),
  borderRadius: theme.shape.borderRadius,
}));

const CTAButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  borderRadius: 24,
  padding: theme.spacing(1.5, 3),
  transition: 'transform 0.3s, box-shadow 0.3s',
  color: theme.palette.common.white,
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.secondary.dark,
  },
}));

function FeaturePage() {
  const { featureId } = useParams();
  const [feature, setFeature] = useState(null);
  const [section, setSection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchFeature = async () => {
      setLoading(true);
      setErrorMsg('');
      try {
        const querySnapshot = await getDocs(collection(db, 'featureSections'));
        let foundFeature = null;
        let foundSection = null;

        querySnapshot.forEach((doc) => {
          const sec = doc.data();
          if (sec.features) {
            const match = sec.features.find((f) => f.id === featureId);
            if (match) {
              foundFeature = match;
              foundSection = sec;
            }
          }
        });

        if (foundFeature && foundSection) {
          setFeature({ ...foundFeature, sectionTitle: foundSection.title });
          setSection(foundSection);
        } else {
          setFeature(null);
        }
      } catch (error) {
        console.error('Error fetching feature:', error);
        setErrorMsg('An error occurred while fetching the feature. Please try again later.');
        setFeature(null);
      } finally {
        setLoading(false);
      }
    };

    fetchFeature();
  }, [featureId]);

  const relatedFeatures = section
    ? section.features.filter((f) => f.id !== featureId)
    : [];

  if (loading) {
    return (
      <Container sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (errorMsg) {
    return (
      <Container sx={{ py: 8 }}>
        <Alert severity="error">{errorMsg}</Alert>
        <Box mt={2}>
          <Link component={RouterLink} to="/" underline="hover">
            <ArrowBackIosNewIcon fontSize="small" sx={{ mr: 0.5 }} />
            Back to Features
          </Link>
        </Box>
      </Container>
    );
  }

  if (!feature) {
    return (
      <Container sx={{ py: 8 }}>
        <Typography variant="h4" gutterBottom>
          Feature Not Found
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          The feature you are looking for does not exist.
        </Typography>
        <Link component={RouterLink} to="/" underline="hover">
          <ArrowBackIosNewIcon fontSize="small" sx={{ mr: 0.5 }} />
          Back to Features
        </Link>
      </Container>
    );
  }

  return (
    <>
      {/* Hero Section */}
      <HeroSection>
        <Container maxWidth="md" sx={{ position: 'relative', zIndex: 2 }}>
          <Box sx={{ textAlign: 'left', mb: 2 }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'white' }}>
              <Link
                component={RouterLink}
                to="/"
                underline="hover"
                sx={{ display: 'inline-flex', alignItems: 'center', color: 'white' }}
              >
                <ArrowBackIosNewIcon fontSize="inherit" sx={{ mr: 0.5 }} />
                All Features
              </Link>
              <Typography color="white">{feature.title}</Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="overline" sx={{ letterSpacing: '0.1em', opacity: 0.8, display: 'block', mb: 1 }}>
            FEATURE HIGHLIGHT
          </Typography>
          <Typography variant={isMobile ? 'h4' : 'h3'} fontWeight="bold" gutterBottom>
            {feature.title}
          </Typography>
          <Typography variant="h6" sx={{ maxWidth: 600, mx: 'auto', opacity: 0.9 }}>
            Discover how {feature.title.toLowerCase()} can elevate your form-building experience and drive better results.
          </Typography>
        </Container>
      </HeroSection>

      <Container sx={{ py: 10 }}>
        {/* Use longDescription here */}
        <Typography variant="body1" sx={{ mb: 4, lineHeight: 1.7 }}>
          {feature.longDescription}
        </Typography>

        {feature.badges && feature.badges.length > 0 && (
          <>
            <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 2 }}>
              Key Attributes
            </Typography>
            <BadgeBox>
              {feature.badges.map((badge, i) => (
                <Badge key={i}>{badge}</Badge>
              ))}
            </BadgeBox>
          </>
        )}

        {feature.sectionTitle && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
            This feature is part of the <strong>{feature.sectionTitle}</strong> section.
          </Typography>
        )}

        <Box mt={4}>
          <Link component={RouterLink} to="/" underline="hover">
            <ArrowBackIosNewIcon fontSize="small" sx={{ mr: 0.5 }} />
            Back to All Features
          </Link>
        </Box>
      </Container>

      {relatedFeatures.length > 0 && (
        <Container sx={{ pb: 10 }}>
          <Divider sx={{ mb: 6 }} />
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center' }}>
            Related Features
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 6, textAlign: 'center', maxWidth: 600, mx: 'auto' }}>
            Explore other features from the <strong>{feature.sectionTitle}</strong> section to further enhance your forms and workflows.
          </Typography>
          <Grid container spacing={4}>
            {relatedFeatures.map((relFeat) => (
              <Grid item xs={12} sm={6} md={4} key={relFeat.id}>
                <RelatedFeatureCard>
                  <RelatedCardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                      {relFeat.title}
                    </Typography>
                    {/* For related features, show the shortDescription */}
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2, lineHeight: 1.6 }}>
                      {relFeat.shortDescription}
                    </Typography>
                    {relFeat.badges && relFeat.badges.length > 0 && (
                      <BadgeBox>
                        {relFeat.badges.map((b, idx) => (
                          <Badge key={idx}>{b}</Badge>
                        ))}
                      </BadgeBox>
                    )}
                  </RelatedCardContent>
                  <RelatedCardActions>
                    <Button
                      variant="text"
                      component={RouterLink}
                      to={`/features/${relFeat.id}`}
                      endIcon={<InfoIcon />}
                      sx={{ textTransform: 'none', fontWeight: 'medium' }}
                    >
                      Learn More
                    </Button>
                  </RelatedCardActions>
                </RelatedFeatureCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}

      {/* CTA Section */}
      <CTASection>
        <Container maxWidth="md">
          <Typography variant={isMobile ? 'h4' : 'h3'} fontWeight="bold" gutterBottom>
            Unlock the Full Potential of Your Forms
          </Typography>
          <Typography variant="h6" sx={{ maxWidth: 600, mx: 'auto', opacity: 0.9, mb: 4 }}>
            Sign up now and experience enhanced capabilities, higher conversion rates, and seamless workflows that put you ahead.
          </Typography>
          <Box>
            <CTAButton
              variant="contained"
              color="secondary"
              size={isMobile ? 'medium' : 'large'}
              component={RouterLink}
              to="/register"
              endIcon={<ArrowForwardIcon />}
              sx={{ mr: 2, backgroundColor: theme.palette.secondary.main }}
            >
              Sign Up for Free
            </CTAButton>
            <CTAButton
              variant="contained"
              color="primary"
              size={isMobile ? 'medium' : 'large'}
              component={RouterLink}
              to="/features"
              endIcon={<InfoIcon />}
              sx={{ backgroundColor: theme.palette.primary.main }}
            >
              Learn More
            </CTAButton>
          </Box>
        </Container>
      </CTASection>
    </>
  );
}

export default FeaturePage;
