// src/components/NavBar.js
import React, { useState, useCallback, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Container,
  Typography,
  Divider,
  Collapse,
  Tooltip,
  Paper,
  Stack,
  ClickAwayListener
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  HelpOutline as HelpIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowRight as ArrowRightIcon,
  Upgrade as UpgradeIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Dashboard as DashboardIcon,
  Insights as InsightsIcon
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

// Firestore imports
import { getFirestore, collection, onSnapshot, query } from 'firebase/firestore';

import connectlyLogo from '/Users/willradley/connectly/src/components/Sales Page/Assets/connectly.png';
import Slide from '@mui/material/Slide';

const guestNavItems = [
  { label: 'Company', path: '/company' },
  { label: 'Contact Sales', path: '/contact-sales' },
  { label: 'Pricing', path: '/pricing' },
];

const guestAuthActions = [
  { label: 'Login', path: '/login' },
  { label: 'Sign Up', path: '/register' },
];

const userNavItems = [
  { label: 'Dashboard', path: '/dashboard' },
  {
    label: 'Forms',
    subItems: [
      { label: 'All Forms', path: '/forms' },
      { label: 'Create Form', path: '/form-builder' },
      { label: 'Templates', path: '/forms/templates' },
      { label: 'Responses', path: '/forms/all-responses' },
    ],
  },
  {
    label: 'CRM',
    subItems: [
      { label: 'Contacts', path: '/contacts' },
      { label: 'Accounts', path: '/accounts' },
    ],
  },
  { label: 'Insights', path: '/insights' },
];

const BrandContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

const Logo = styled('img')(({ theme }) => ({
  height: 40,
  marginRight: theme.spacing(1.5),
}));

const PanelWrapper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(100% + 10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  zIndex: theme.zIndex.modal,
  animation: 'fadeIn 0.2s ease',
  '@keyframes fadeIn': {
    '0%': { opacity: 0, transform: 'translateY(-5px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },
}));

function NavBar() {
  const [user] = useAuthState(auth);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openPanel, setOpenPanel] = useState(null);
  const [profilePanelOpen, setProfilePanelOpen] = useState(false);
  const [helpPanelOpen, setHelpPanelOpen] = useState(false);
  const [articles, setArticles] = useState([]);

  const db = getFirestore();

  const navItems = user ? userNavItems : guestNavItems;
  const authActions = user ? [] : guestAuthActions;

  const handleNavigation = (path) => {
    navigate(path);
    closeAllPanels();
  };

  const closeAllPanels = () => {
    setDrawerOpen(false);
    setOpenPanel(null);
    setProfilePanelOpen(false);
    setHelpPanelOpen(false);
  };

  const handleLogout = useCallback(async () => {
    await signOut(auth);
    navigate('/login');
  }, [navigate]);

  const handleMainItemClick = (item) => {
    if (item.subItems) {
      setOpenPanel((prev) => (prev === item.label ? null : item.label));
    } else {
      handleNavigation(item.path);
    }
  };

  const isActive = (path) => location.pathname.startsWith(path || '');

  const handleProfileClick = () => {
    setProfilePanelOpen((prev) => !prev);
    setHelpPanelOpen(false);
    setOpenPanel(null);
  };

  const handleHelpClick = () => {
    setHelpPanelOpen((prev) => !prev);
    setProfilePanelOpen(false);
    setOpenPanel(null);
  };

  const handleClickAway = () => {
    setProfilePanelOpen(false);
    setHelpPanelOpen(false);
  };

  // Fetch articles from Firestore
  useEffect(() => {
    const q = query(collection(db, 'knowledgeBase'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetched = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setArticles(fetched);
    });
    return () => unsubscribe();
  }, [db]);

  // Filter articles based on current route
  const relatedArticles = articles.filter((article) =>
    article.routes && article.routes.some((r) => location.pathname.startsWith(r))
  );

  const desktopNavItems = () => (
    <Box display="flex" alignItems="center" gap={3}>
      {navItems.map((item) => (
        <Button
          key={item.label}
          onClick={() => handleMainItemClick(item)}
          sx={{
            textTransform: 'none',
            fontWeight: 'medium',
            color: isActive(item.path) ? theme.palette.primary.main : theme.palette.common.white,
            position: 'relative',
            '&:hover': { 
              color: theme.palette.primary.main,
              '&::after': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '2px',
                bottom: '-4px',
                left: 0,
                backgroundColor: theme.palette.primary.main,
              },
            },
            transition: 'color 0.2s ease',
          }}
          endIcon={item.subItems && <ExpandMoreIcon sx={{ fontSize: '1.1rem' }} />}
          aria-haspopup={item.subItems ? 'true' : 'false'}
          aria-expanded={openPanel === item.label ? 'true' : 'false'}
        >
          {item.label}
        </Button>
      ))}
    </Box>
  );

  const mobileNavItemsContent = navItems.map((item) => {
    const hasSubItems = !!item.subItems;
    const isOpen = openPanel === item.label;
    return (
      <React.Fragment key={item.label}>
        <ListItem
          button
          onClick={() => {
            if (hasSubItems) {
              setOpenPanel((prev) => (prev === item.label ? null : item.label));
            } else {
              handleNavigation(item.path);
            }
          }}
        >
          <ListItemText
            primary={item.label}
            primaryTypographyProps={{
              fontWeight: isActive(item.path) ? 'bold' : 'medium',
              color: '#fff',
            }}
          />
          {hasSubItems && <ArrowRightIcon sx={{ color: '#fff' }} />}
        </ListItem>
        {hasSubItems && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {item.subItems.map((subItem) => (
              <ListItem
                button
                key={subItem.label}
                sx={{ pl: 4 }}
                onClick={() => handleNavigation(subItem.path)}
                selected={location.pathname === subItem.path}
              >
                <ListItemText primary={subItem.label} primaryTypographyProps={{ color: '#fff' }} />
              </ListItem>
            ))}
          </Collapse>
        )}
      </React.Fragment>
    );
  });

  return (
    <>
      <AppBar
        position="static"
        elevation={2}
        sx={{
          background: `linear-gradient(to right, ${theme.palette.grey[900]} 0%, ${theme.palette.grey[800]} 100%)`,
        }}
      >
        <Toolbar>
          <BrandContainer onClick={() => handleNavigation('/')}>
            <Logo src={connectlyLogo} alt="Connectly Logo" />
          </BrandContainer>

          {!isMobile && (
            <Box ml={4}>{desktopNavItems()}</Box>
          )}

          <Box sx={{ flexGrow: 1 }} />

          {!isMobile && (
            <Box display="flex" alignItems="center" gap={2}>
              {user && (
                <Typography variant="body2" color="common.white" sx={{ mr: 2 }}>
                  Welcome, <strong>{user.displayName || 'User'}</strong>
                </Typography>
              )}

              <Tooltip title="Upgrade">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<UpgradeIcon />}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '20px',
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      backgroundColor: theme.palette.secondary.dark,
                    },
                  }}
                  onClick={() => handleNavigation('/pricing')}
                >
                  Upgrade
                </Button>
              </Tooltip>

              {/* Help Icon and Panel */}
              <Box sx={{ position: 'relative', display: 'inline-block' }}>
                <Tooltip title="Help & Articles">
                  <IconButton color="inherit" onClick={handleHelpClick}>
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
                {helpPanelOpen && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box sx={{ position: 'relative' }}>
                      <PanelWrapper sx={{ width: 300, right: 0 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'medium', mb: 1 }}>
                          Need Help?
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                          Check out these articles or visit our Help Centre.
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Stack spacing={1} sx={{ mb: 2 }}>
                          {relatedArticles.length > 0 ? (
                            relatedArticles.map((article) => (
                              <Button
                                key={article.id}
                                variant="text"
                                sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
                                onClick={() => {
                                  if (article.link.startsWith('http')) {
                                    window.open(article.link, '_blank');
                                  } else {
                                    handleNavigation(article.link);
                                  }
                                }}
                              >
                                {article.title}
                              </Button>
                            ))
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              No related articles found.  
                            </Typography>
                          )}
                        </Stack>
                        <Divider sx={{ mb: 2 }} />
                        <Button
                          variant="outlined"
                          onClick={() => handleNavigation('/knowledge-base')}
                          sx={{ textTransform: 'none' }}
                        >
                          Go to Knowledge Base
                        </Button>
                      </PanelWrapper>
                    </Box>
                  </ClickAwayListener>
                )}
              </Box>

              {/* Profile Icon and Panel */}
              {user ? (
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <Tooltip title="Profile & Settings">
                    <IconButton color="inherit" onClick={handleProfileClick}>
                      <AccountCircleIcon />
                    </IconButton>
                  </Tooltip>
                  {profilePanelOpen && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box sx={{ position: 'relative' }}>
                        <PanelWrapper sx={{ width: 260, right: 0 }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 'medium', mb: 1 }}>
                            Hello, {user.displayName || 'there'}!
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                            Discover new insights and updates right from your dashboard.
                          </Typography>

                          <Divider sx={{ mb: 2 }} />

                          <Stack spacing={1} sx={{ mb: 2 }}>
                            <Button
                              variant="text"
                              startIcon={<DashboardIcon />}
                              onClick={() => handleNavigation('/dashboard')}
                              sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
                            >
                              My Dashboard
                            </Button>
                            <Button
                              variant="text"
                              startIcon={<InsightsIcon />}
                              onClick={() => handleNavigation('/insights')}
                              sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
                            >
                              Insights
                            </Button>
                            <Button
                              variant="text"
                              startIcon={<HelpIcon />}
                              onClick={() => handleNavigation('/help-and-support')}
                              sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
                            >
                              Help & Support
                            </Button>
                          </Stack>

                          <Divider sx={{ mb: 2 }} />

                          <Stack spacing={1}>
                            <Button
                              variant="text"
                              startIcon={<SettingsIcon />}
                              onClick={() => handleNavigation('/profile-settings')}
                              sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
                            >
                              Settings
                            </Button>
                            <Button
                              variant="text"
                              startIcon={<LogoutIcon />}
                              onClick={handleLogout}
                              sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
                            >
                              Log Out
                            </Button>
                          </Stack>
                        </PanelWrapper>
                      </Box>
                    </ClickAwayListener>
                  )}
                </Box>
              ) : (
                authActions.map((action) => (
                  <Button
                    key={action.label}
                    onClick={() => handleNavigation(action.path)}
                    sx={{
                      textTransform: 'none',
                      fontWeight: 'medium',
                      color: '#fff',
                      '&:hover': { color: theme.palette.primary.main },
                    }}
                  >
                    {action.label}
                  </Button>
                ))
              )}
            </Box>
          )}

          {isMobile && (
            <IconButton
              color="inherit"
              onClick={() => setDrawerOpen(true)}
              aria-label="open menu"
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box
          sx={{
            width: 250,
            background: `linear-gradient(to bottom, ${theme.palette.grey[900]} 0%, ${theme.palette.grey[800]} 100%)`,
            height: '100%',
            color: '#fff',
            p: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Menu
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, opacity: 0.7 }}>
            Quick Navigation
          </Typography>
          <Divider sx={{ mb: 2, backgroundColor: '#fff', opacity: 0.2 }} />
          <List disablePadding>{mobileNavItemsContent}</List>
          <Divider sx={{ my: 2, backgroundColor: '#fff', opacity: 0.2 }} />
          <List disablePadding>
            <ListItem button onClick={() => handleNavigation('/pricing')}>
              <ListItemText primary="Upgrade" primaryTypographyProps={{ color: '#fff' }} />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/help-and-support')}>
              <ListItemText primary="Help & Support" primaryTypographyProps={{ color: '#fff' }} />
            </ListItem>
          </List>
          <Divider sx={{ my: 2, backgroundColor: '#fff', opacity: 0.2 }} />

          <List disablePadding>
            {user ? (
              <>
                <ListItem button onClick={() => handleNavigation('/profile-settings')}>
                  <ListItemText primary="Settings" primaryTypographyProps={{ color: '#fff' }} />
                </ListItem>
                <ListItem button onClick={handleLogout}>
                  <ListItemText primary="Log Out" primaryTypographyProps={{ color: '#fff' }} />
                </ListItem>
              </>
            ) : (
              authActions.map((action) => (
                <ListItem
                  button
                  key={action.label}
                  onClick={() => handleNavigation(action.path)}
                  selected={location.pathname === action.path}
                >
                  <ListItemText
                    primary={action.label}
                    primaryTypographyProps={{
                      color: '#fff',
                      fontWeight: location.pathname === action.path ? 'bold' : 'medium',
                    }}
                  />
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Drawer>

      {/* Desktop Submenu */}
      {!isMobile && openPanel && (
        <Slide direction="down" in={!!openPanel} mountOnEnter unmountOnExit>
          <Paper 
            elevation={4}
            sx={{
              position: 'relative',
              width: '100%',
              backgroundColor: theme.palette.background.paper,
              zIndex: theme.zIndex.appBar - 1,
              borderTop: `1px solid ${theme.palette.divider}`,
              py: 3,
              boxShadow: theme.shadows[5],
              animation: 'fadeIn 0.2s ease',
              '@keyframes fadeIn': {
                '0%': { opacity: 0, transform: 'translateY(-5px)' },
                '100%': { opacity: 1, transform: 'translateY(0)' },
              },
            }}
          >
            <Box sx={{ position: 'absolute', top: 8, right: 16 }}>
              <IconButton onClick={() => setOpenPanel(null)} aria-label="Close submenu">
                <CloseIcon />
              </IconButton>
            </Box>
            <Container maxWidth="lg">
              {navItems
                .filter((item) => item.label === openPanel && item.subItems)
                .map((item) => (
                  <Stack direction="row" spacing={4} key={item.label}>
                    {item.subItems.map((subItem) => (
                      <Button
                        key={subItem.label}
                        onClick={() => handleNavigation(subItem.path)}
                        sx={{
                          textTransform: 'none',
                          fontWeight: location.pathname === subItem.path ? 'bold' : 'medium',
                          color: location.pathname === subItem.path
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                          '&:hover': { color: theme.palette.primary.main },
                        }}
                      >
                        {subItem.label}
                      </Button>
                    ))}
                  </Stack>
                ))}
            </Container>
          </Paper>
        </Slide>
      )}
    </>
  );
}

export default NavBar;
