// src/AppRoutes.js
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './components/NotFound';
import FeaturePage from './pages/FeaturePage';

const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Contacts = lazy(() => import('./components/Contacts/Contacts'));
const ContactDetail = lazy(() => import('./components/Contacts/Detail/ContactDetail'));
const Accounts = lazy(() => import('./components/Accounts/Accounts'));
const AccountDetail = lazy(() => import('./components/Accounts/Detail/AccountDetail'));
const ProfileSettings = lazy(() => import('./components/Settings/ProfileSettings'));
const FormBuilder = lazy(() => import('./components/Forms/FormBuilder/FormBuilder'));
const FormList = lazy(() => import('./components/Forms/FormList'));
const FormDisplay = lazy(() => import('./components/Forms/FormDisplay/FormDisplay'));
const Login = lazy(() => import('./components/Auth/Login'));
const Register = lazy(() => import('./components/Auth/Register'));
const ForgotPassword = lazy(() => import('./components/Auth/ForgotPassword'));
const SalesPage = lazy(() => import('./components/Sales Page/SalesPage'));
const HelpAndSupport = lazy(() => import('./components/HelpAndSupport/HelpAndSupportPage'));
const Notifications = lazy(() => import('./components/Notifications'));
const FormSubmissions = lazy(() => import('./components/FormSubmissions'));
const PricingPage = lazy(() => import('./pages/PricingPage'));
const Templates = lazy(() => import('./components/Forms/FormTemplates/TemplatesPage'));
const ContactSales = lazy(() => import('./components/Sales Page/ContactSalesPage'));
const CompanyPage = lazy(() => import('./pages/CompanyPage'));
const AllResponses = lazy(() => import('./components/Forms/AllResponses'));
const ThankYou = lazy(() => import('./components/ThankYou'));
const Insights = lazy(() => import('./components/Insights/Insights'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('./pages/TermsOfServicePage'));
const FeaturesOverviewPage = lazy(() => import('./pages/FeaturesOverviewPage'));
const KnowledgeBaseHome = lazy(() => import('./components/KnowledgeBaseHome'));
const KnowledgeBaseArticle = lazy(() => import('./components/KnowledgeBaseArticle'));

function AppRoutes() {
  return (
    <Routes>
      {/* Public Pages */}
      <Route path="/" element={<SalesPage />} />
      <Route path="/features" element={<FeaturesOverviewPage />} />
      <Route path="/features/:featureId" element={<FeaturePage />} />
      <Route path="/company" element={<CompanyPage />} />
      <Route path="/contact-sales" element={<ContactSales />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/help-and-support" element={<HelpAndSupport />} />
      <Route path="/forms/:id" element={<FormDisplay />} />
      <Route path="/policy/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/policy/terms" element={<TermsOfServicePage />} />

      {/* Protected Pages */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/knowledge-base"
        element={
          <ProtectedRoute>
            <KnowledgeBaseHome />
          </ProtectedRoute>
        }
      />

      {/* Dynamic Article Route */}
      <Route
        path="/knowledge-base/article/:articleId"
        element={
          <ProtectedRoute>
            <KnowledgeBaseArticle />
          </ProtectedRoute>
        }
      />

      <Route
        path="/insights"
        element={
          <ProtectedRoute>
            <Insights />
          </ProtectedRoute>
        }
      />

      <Route
        path="/thank-you"
        element={
          <ProtectedRoute>
            <ThankYou />
          </ProtectedRoute>
        }
      />
      <Route
        path="/contacts"
        element={
          <ProtectedRoute>
            <Contacts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/contacts/:id"
        element={
          <ProtectedRoute>
            <ContactDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/accounts"
        element={
          <ProtectedRoute>
            <Accounts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/accounts/:id"
        element={
          <ProtectedRoute>
            <AccountDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile-settings"
        element={
          <ProtectedRoute>
            <ProfileSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/form-builder"
        element={
          <ProtectedRoute>
            <FormBuilder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/form-builder/:formId"
        element={
          <ProtectedRoute>
            <FormBuilder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/forms/create"
        element={
          <ProtectedRoute>
            <FormBuilder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/forms/create/:templateId"
        element={
          <ProtectedRoute>
            <FormBuilder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/forms"
        element={
          <ProtectedRoute>
            <FormList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/forms/:formId/submissions"
        element={
          <ProtectedRoute>
            <FormSubmissions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/forms/templates"
        element={
          <ProtectedRoute>
            <Templates />
          </ProtectedRoute>
        }
      />
      <Route
        path="/forms/all-responses"
        element={
          <ProtectedRoute>
            <AllResponses />
          </ProtectedRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoute>
            <Notifications />
          </ProtectedRoute>
        }
      />

      {/* 404 Not Found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
