// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth,GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyBx-hNI5Vt-9xZDsvaoeBmn9a5bDWDRX7Q",
  authDomain: "finli-efed8.firebaseapp.com",
  projectId: "finli-efed8",
  storageBucket: "finli-efed8.firebasestorage.app",
  messagingSenderId: "536456391206",
  appId: "1:536456391206:web:a6af1cf1d4822ff2f0d4e7",
  measurementId: "G-LVP5P6G12M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, "us-central1"); // e.g., "us-central1"
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Google Auth Provider
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app);

export { auth, db, googleProvider, storage, functions };